import React from "react"
import { Link } from "gatsby"

import FPLayout from "../components/fplayout"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "reactstrap"

import Image from "../components/image"

import styled from "styled-components"

const FrontPage = () => (
  <FPLayout>
    <SEO title="Welcome To Chicken Yard | Chicken Yard" />
    <Container fluid={true}>
      <Row>
        <Col className="px-0 fpbg">
          <div className="text-center fpjumbo p-0">
            <Image />
          </div>
          <Container>
            <Row>
              <Col>
                <CallToAction>
                  <CallToActionHeading>
                    <span style={{ color: `#e39f1e` }}>Got chickens?</span>
                    <br /> We do too.
                  </CallToActionHeading>
                  <hr className="my-2" />
                  <p style={{ fontSize: `1.25em` }}>
                    We've got everything you need to know about keeping poultry.
                    <br />
                    <strong>Right here.</strong>
                  </p>
                  <p className="lead">
                    <Link to="/blog/">
                      <Button className="jumbobutton py-3" size="lg">
                        Enter
                      </Button>
                    </Link>
                  </p>
                </CallToAction>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  </FPLayout>
)

export default FrontPage

const CallToAction = styled.div`
  border-radius: 5px;
  background: #231f1a;
  color: #fff;
  margin-bottom: -160px;
  position: relative;
  top: -180px;
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  text-align: center;
  min-height: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
`
const CallToActionHeading = styled.h1`
  font-family: "Jost";
  font-size: 2.3rem;
`
